import logo from './logo.svg';
import './App.css';
import Header from "./Header";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import TinderCards from "./TinderCards";
import {useState} from "react";
import {Helmet} from "react-helmet";
function App() {

    return (
        <div className="App">
            <Helmet>
           <script src="https://telegram.org/js/telegram-web-app.js"            type="text/javascript"            />
        </Helmet>
            <Header/>
            <Router>
                <Routes>
                    <Route path={"/chat"}>
                        I am chat
                    </Route>
                    <Route path={'/'} element={<TinderCards/>}/>
                </Routes>
                {/*<Header/>*/}
            </Router>

        </div>
    );
}

export default App;
