import React, {useState} from 'react';
import TinderCard from "react-tinder-card";
import './TinderCards.css'

function TinderCards(props) {
    const [people, setPeople] = useState([{
        name: "test1",
        url: 'https://wallpapers.com/images/featured/cool-men-d7ag46klh1n45y8b.jpg'
    }, {
        name: "test2",
        url: 'https://c7.alamy.com/comp/2A6105J/portrait-of-cool-man-with-sunglasses-and-headphones-in-the-city-2A6105J.jpg'
    }])
    return (
        <div>
            <div className="tinderCard_container">
                {people.map(person => (<TinderCard className="swipe" key={person.name}>
                    <div style={{backgroundImage: `url(${person.url})`}} className="tinderCard"><h3>{person.name}</h3>
                    </div>
                </TinderCard>))}
            </div>
        </div>
    );
}

export default TinderCards;